import React from "react";
import { Link } from "react-router-dom";

import "../css/Services.css";
import { Trans, useTranslation } from "react-i18next";

import innovation from "../assets/innovation.png";
import sustainability from "../assets/sustainability.png";
import innovationIcon from "../assets/innovation_icon.png";
import personalizationIcon from "../assets/personalization_icon.png";
import sustainabilityIcon from "../assets/sustainability_icon.png";
import scalableIcon from "../assets/scalable_icon.png";
import developIcon from "../assets/develop_icon.png";
import researchIcon from "../assets/reserch_icon.png";
import requirementsIcon from "../assets/requirements_icon.png";

function Services() {
  const { t } = useTranslation();

  return (
    <div className="container container-services">
      <h2 className="title text-start mt-5 mb-4">
        {t("main.services.whatweoffer")}
      </h2>
      <div className="wrapper-info-services">
        <Trans>{t("main.services.whatweoffertext")}</Trans>
      </div>
      <div className="wrapper-buttons d-flex justify-content-center mt-5 mb-5 pb-5">
        <div className="button-products mx-5">
          {t("main.products.development")}
        </div>
        <div className="button-products mx-5">
          {t("main.services.consulting")}
        </div>
        <div className="button-products mx-5">{t("main.services.design")}</div>
        <div className="button-products mx-5">
          {t("main.services.brandIdentity")}
        </div>
        <div className="button-products mx-5">
          {t("main.services.digitalization")}
        </div>
      </div>
      <div className="container container-wrapper-info d-lg-flex justify-content-center mt-4 mb-4 pb-3 pt-2">
        <div className="col-lg-6 pe-3">
          <div className="services-wrapper mb-5">
            <img
              src={innovationIcon}
              className="mb-3 icon"
              alt="Innovation icon"
            />
            <h3>{t("main.services.innovation")}</h3>
            <p>{t("main.services.innovationText")}</p>
            <img src={innovation} className="services-img" alt="Innovation" />
          </div>
          <div className="services-wrapper">
            <img src={scalableIcon} className="mb-3 icon" alt="Scalable icon" />
            <h3>{t("main.services.scalable")}</h3>
            <p>{t("main.services.scalableText")}</p>
          </div>
        </div>
        <div className="col-lg-6 ps-3">
          <div className="services-wrapper mb-5">
            <img
              src={personalizationIcon}
              className="mb-3 icon"
              alt="Personalization icon"
            />
            <h3>{t("main.services.personalization")}</h3>
            <p>{t("main.services.personalizationText")}</p>
          </div>
          <div className="services-wrapper">
            <img
              src={sustainabilityIcon}
              className="mb-3 icon"
              alt="Sustainability icon"
            />
            <h3>{t("main.services.sustainability")}</h3>
            <p>{t("main.services.sustainabilityText")}</p>
            <img
              src={sustainability}
              className="services-img"
              alt="Sustainability"
            />
          </div>
        </div>
      </div>
      <div className="container container-how-we-do-it mt-4 pt-2 pb-5">
        <h2 className="text-start mt-5 mb-4">{t("main.services.howwedoit")}</h2>
        <p>{t("main.services.howwedoittext")}</p>
        <div className="d-lg-flex justify-content-center mt-5 mb-5">
          <div className="col-lg-4 pe-4">
            <div className="services-wrapper">
              <img
                src={requirementsIcon}
                className="mb-3 icon"
                alt="Requirements icon"
              />
              <h3>{t("main.services.requirements")}</h3>
              <Trans>{t("main.services.requirementsText")}</Trans>
            </div>
          </div>
          <div className="col-lg-4 px-4">
            <div className="services-wrapper">
              <img
                src={researchIcon}
                className="mb-3 icon"
                alt="Research icon"
              />
              <h3>{t("main.services.research")}</h3>
              <p>{t("main.services.researchText")}</p>
            </div>
          </div>
          <div className="col-lg-4 ps-4">
            <div className="services-wrapper">
              <img src={developIcon} className="mb-3 icon" alt="Develop icon" />
              <h3>{t("main.services.develop")}</h3>
              <p>{t("main.services.developText")}</p>
            </div>
          </div>
        </div>
        <div className="pt-5 pb-5 d-flex justify-content-center">
          <Link className="button-disc" to={"/contact-us"}>
            {t("main.contactUs")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Services;
