import React from "react";

import icashly_logo from "../assets/icashly_logo.png";

import "../css/Products.css";
import icashly from "../assets/iCashly.png";
import { useTranslation } from "react-i18next";

function Products() {
  const { t } = useTranslation();

  return (
    <div className="products-wrapper">
      <div className="container">
        <div className="mt-4 pt-5 wrapper-icashly-desc">
          <img width="350" src={icashly_logo} alt="iCashly logo" />
          <p className="pt-4 pb-5">{t("main.products.icashly_desc")}</p>
        </div>
        <div className="d-lg-flex wrapper-buttons justify-content-center mt-4 mb-5">
          <div className="button-products mx-5">
            {t("main.products.concept")}
          </div>
          <div className="button-products mx-5">
            {t("main.products.brand_identity")}
          </div>
          <div className="button-products mx-5">
            {t("main.products.architecture")}
          </div>
          <div className="button-products mx-5">{t("main.products.ux_ui")}</div>
          <div className="button-products mx-5">
            {t("main.products.development")}
          </div>
        </div>
        <img
          className="mt-5 mb-5 banner"
          width="100%"
          height="auto"
          alt="iCashly banner"
          src={icashly}
        />
      </div>
    </div>
  );
}

export default Products;
