import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../css/AboutUs.css";
import dandrea from "../assets/dandrea_governance.png";
import gervasi from "../assets/gervasi_governance.png";
import orefice from "../assets/orefice_governance.png";

import { Trans, useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";

function AboutUs() {
  const { t } = useTranslation();

  const [videoHome, setVideoHome] = useState("");

  const downloadFile = async () => {
    const getUrlResult = await getUrl({
      path: "public/weare_video_andromeda.mp4",
    });
    setVideoHome(getUrlResult.url.toString());
  };
  useEffect(() => {
    downloadFile();
  });

  return (
    <div className="container container-aboutus">
      <div className="d-flex justify-content-center">
        <video
          src={videoHome}
          width="900"
          height="550"
          autoPlay
          muted
          loop
          playsInline
        />
      </div>
      <div className="mb-5 pb-3">
        <h2 className="text-start mb-3">{t("main.weare.weareandromeda")}</h2>
        <Trans>{t("main.weare.weare_desc")}</Trans>
      </div>
      <div className="mt-5 mb-5 pb-3">
        <h2 className="text-start mb-3">Mission</h2>
        <Trans>{t("main.weare.mission_desc")}</Trans>
      </div>
      <div className="mt-5 mb-5 pb-3">
        <h2 className="text-start mb-3">{t("main.weare.values")}</h2>
        <div className="d-lg-flex values-container justify-content-center text-center mt-3 mb-3 pb-3">
          <div className="pe-4 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">
              {t("main.weare.sustainability")}
            </h4>
            <p className="text-start">{t("main.weare.sustainability_desc")}</p>
          </div>
          <div className="px-4 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">
              {t("main.weare.gender_equality")}
            </h4>
            <p className="text-start">{t("main.weare.gender_equality_desc")}</p>
          </div>
          <div className="ps-4 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">
              {t("main.weare.inclusivity")}
            </h4>
            <p className="text-start">{t("main.weare.inclusivity_desc")}</p>
          </div>
        </div>
        <div className="d-lg-flex values-container text-start mt-3 mb-5 pb-3">
          <div className="pe-4 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">
              {t("main.weare.new_gen")}
            </h4>
            <p className="text-start">{t("main.weare.new_gen_desc")}</p>
          </div>
          <div className="px-4 col-lg-4">
            <h4 className="pt-3 pb-2 name text-start">
              {t("main.weare.integrity")}
            </h4>
            <p className="text-start">{t("main.weare.integrity_desc")}</p>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <h2 className="text-start">Governance</h2>
        <div className="d-lg-flex justify-content-center governance-container mt-3 pt-5 mb-3 pb-3">
          <div className="px-5 mx-5">
            <div className="pt-3 pb-4">
              <img src={gervasi} alt="Salvatore Gervasi" />
            </div>
            <p className="text-start">Salvatore Gervasi</p>
            <p className="text-start">CEO</p>
            <p className="text-start">s.gervasi@rigsavecapital.com</p>
            <Link
              className="linkedin-coll text-start"
              to={"https://www.linkedin.com/in/salvatore-gervasi-443404112/"}
            >
              Linkedin
            </Link>
          </div>
          <div className="px-5 mx-5">
            <div className="pt-3 pb-4">
              <img src={orefice} alt="Bruno Orefice" />
            </div>
            <p className="text-start">Bruno Orefice</p>
            <p className="text-start">CTO</p>
            <p className="text-start">b.orefice@rigsave.com</p>
            <Link
              className="linkedin-coll text-start"
              to={"https://www.linkedin.com/in/brunoorefice/"}
            >
              Linkedin
            </Link>
          </div>
          <div className="px-5 mx-5">
            <div className="pt-3 pb-4">
              <img src={dandrea} alt="Rachele d'Andrea" />
            </div>
            <p className="text-start">Rachele D'Andrea</p>
            <p className="text-start">Head of Design</p>
            <p className="text-start">r.dandrea@rigsave.com</p>
            <Link
              className="linkedin-coll text-start"
              to={"https://www.linkedin.com/in/rachele-d-andrea-21b16b20a/"}
            >
              Linkedin
            </Link>
          </div>
        </div>
        <div className="pt-5 pb-5 d-flex justify-content-center">
          <Link className="button-disc" to={"/contact-us"}>
            {t("main.contactUs")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
