import React from "react";
import { Link } from "react-router-dom";

import {
  faSquareFacebook,
  faSquareInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pittogramma from "../assets/footer_pittogramma.png";

import "../css/Footer.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <div className="footer-container row row-footer-legal row-info pt-4 pb-2">
          <div className="col-lg-2 col-sm-6 col-md-6">
            <div className="pb-1">
              <p className="title-text-footer mb-2">
                {t("main.footer.company")}
              </p>
              <Link to={"/"} className="list-inline text-start">
                <p className="mb-1">{t("main.header.home")}</p>
              </Link>
              <Link to={"/about-us"} className="list-inline text-start">
                <p className="mb-1">{t("main.header.aboutUs")}</p>
              </Link>
              <Link to={"/services"} className="list-inline text-start">
                <p className="mb-1">{t("main.header.services")}</p>
              </Link>
              <Link to={"products/"} className="list-inline text-start">
                <p className="mb-1">{t("main.header.products")}</p>
              </Link>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 col-md-6">
            <div>
              <p className="title-text-footer mb-2">
                {t("main.footer.social")}
              </p>
            </div>
            <div className="d-flex">
              {/* <Link target="_blank" to={"https://www.facebook.com/rigsave"}>
                <p className="pe-2">
                  <FontAwesomeIcon
                    className="font-social"
                    icon={faSquareFacebook}
                  />
                </p>
              </Link> */}
              <Link
                target="_blank"
                to={"https://www.linkedin.com/company/andromeda-enterprises/"}
              >
                <p className="px-2">
                  <FontAwesomeIcon className="font-social" icon={faLinkedin} />
                </p>
              </Link>
              <Link
                target="_blank"
                to={"https://www.instagram.com/andromeda_enterprises/"}
              >
                <p className="px-2">
                  <FontAwesomeIcon
                    className="font-social"
                    icon={faSquareInstagram}
                  />
                </p>
              </Link>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 col-md-6">
            <p className="title-text-footer mb-2">
              {t("main.footer.workWithUs")}
            </p>
            <p className="mb-1">
              <Link to={"/contact-us"}>{t("main.footer.sendCV")}</Link>
            </p>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6">
            <div>
              <p className="title-text-footer mb-2">
                {t("main.footer.contactUs")}
              </p>
            </div>
            <p className="pec-footer">
              <Link
                to="javascript:void(0)"
                onClick={(e) =>
                  (window.location.href =
                    "mailto:hello@andromedaenterprises.com")
                }
              >
                hello@andromedaenterprises.com
              </Link>
            </p>
            <p>C.so Giuseppe Zanardelli 38, Brescia (BS)</p>
            <p>(+39) 03 0375 6520</p>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6 pittogramma-wrapper-footer">
            <div className="footer-ico-wrapper">
              <img width="200" src={pittogramma} alt="Andromeda pittogramma" />
            </div>
          </div>
        </div>
        <div className="d-lg-flex row row-footer-legal pt-3 pb-3">
          <div className="col-lg-6">
            <p className="title">Rigsave tech srl</p>
            <p>
              Copyright © Rigsave Tech Srl | C.so Giuseppe Zanardelli 38,
              Brescia (BS), Italia | VAT, tax code, and number of registration
              with the Brescia Company Register P.IVA 04094820984 | REA number
              BS 587876
            </p>
          </div>
          <div className="col-lg-6">
            <div className="text-end mt-3">
              <Link
                to={"https://www.iubenda.com/privacy-policy/32717822"}
                className="nav-link px-5 text-end"
              >
                Privacy policy
              </Link>
              <Link
                to={
                  "https://www.iubenda.com/privacy-policy/32717822/cookie-policy"
                }
                className="nav-link px-5 text-end"
              >
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
