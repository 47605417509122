import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function TranslatorComponent() {
  const { i18n } = useTranslation();
  const [isActiveEng, setActiveEng] = useState(true);
  const [isActiveIta, setActiveIta] = useState(false);

  const changeLngEn = () => {
    i18n.changeLanguage("en");
    setActiveEng(!isActiveEng);
    setActiveIta(false);
  };

  const changeLngIT = () => {
    i18n.changeLanguage("it");
    setActiveEng(false);
    setActiveIta(!isActiveIta);
  };

  return (
    <div className="picker-lang-andromeda pe-3">
      <button className={isActiveIta ? "active" : null} onClick={changeLngIT}>
        IT
      </button>
      <span> </span>
      <span>/</span>
      <span> </span>
      <button className={isActiveEng ? "active" : null} onClick={changeLngEn}>
        EN
      </button>
    </div>
  );
}

export default TranslatorComponent;
