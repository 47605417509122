import React from "react";

import { useTranslation } from "react-i18next";

import "../css/ContactUs.css";

import findUsIcon from "../assets/icon_vettoriali_04.png";
import sendCvIcon from "../assets/icon_vettoriali_03.png";
import contactUsIcon from "../assets/icon_vettoriali_13.png";
import Contact from "components/Contact";

function ContactUs() {
  const { t } = useTranslation();

  return (
    <div className="container container-contactUs pb-5">
      <div className="pt-5 pb-5">
        <h2 className="title">{t("main.contactUsPage.title")}</h2>
      </div>
      <div className="mt-3 mb-4 d-lg-flex justify-content-center">
        <div className="col-lg-4 pe-5">
          <img src={findUsIcon} alt="Find us icon" />
          <h3 className="pt-3 m-0">{t("main.contactUsPage.letsFindUs")}</h3>
          <p className="pt-2 pb-3">{t("main.contactUsPage.letsFindUsText")}</p>
        </div>
        <div className="col-lg-4 px-5">
          <img src={sendCvIcon} alt="Send CV icon" />
          <h3 className="pt-3 m-0">{t("main.contactUsPage.sendCvTitle")}</h3>
          <p className="pt-2 pb-3">{t("main.contactUsPage.sendCvText")}</p>
        </div>
        <div className="col-lg-4 ps-5">
          <img src={contactUsIcon} alt="Contacts us icon" />
          <h3 className="pt-3 m-0">{t("main.contactUsPage.contactUs")}</h3>
          <p className="pt-2">hello@andromedaenterprises.com</p>
          <p className="pb-3">(+39) 03 0375 6520</p>
        </div>
      </div>
      {/* <Contact /> */}
    </div>
  );
}

export default ContactUs;
